html, body { max-width: 100% !important; overflow-x: hidden !important; }
.App{
  /* padding: 15px; */

}

.main-page {
  background-color: white;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  min-width: 100vw;
  margin: 0;
  min-height: 100vh;
  /* display: grid; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-content: center; */
  

}

.cart{
 background-color: #e9ecef;
 background-image: url("/public/Images/backgroundCityGorillaz.png");
 background-size: 100vw;
 background-repeat: no-repeat;
 background-position-y: -40vh;

}





.inner-cart{
  display: grid;
  grid-template-columns: 35% 35% 10% 20%;
  grid-template-rows: 10% 45% 45%;
}

.content-cart{
  display: grid;
  grid-template-columns: 35% 35% 10% 20%;
  
}

.spikes-img{
}

.main-page>.main-page-product{
  display: flex;
  flex-direction: row;
  width: 92.5vw;
  --s: 100px;
  
  display: grid;
  height: 100%;
  grid-auto-flow: column;
  justify-content: center;
  margin: 0 auto;
  margin-top: 10vh;
  /* background-color: #212529; */
  background-image: url('/public/Images/backgroundCityGorillaz.png');
  background-size: 15vw;
  padding: 50px;
  background-repeat: no-repeat;

}



















.App-header{
  
  height: 20h;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -16vh;
  /* background-image: url('/public/Images/backgroundCityGorillaz2.png'); */
  background-position-y: -100vh;
  
  

}

#header-stylish-dividers{
  /* background-image: url('/public/Images/backgroundCityGorillaz2.png'); */
}

.dropdown-nutrition{
  visibility: hidden;
}

.dropdown-header{
  display: flex;
  justify-content: left;
}

.dropdown-text-nutrition{
  position: absolute;
  left: 0;
  margin-left: 2vw;
  color:white;
  margin-top: 7.7vh;
  /* visibility: hidden; */

}



.dropdown-text-nutrition:hover{
  /* text-decoration: underline; */
  

}

.dropdown-text-nutrition:hover>.dropdown-nutrition{
  visibility: visible;
  background-color: #212529;
  width: 100vw;
  height: 20vh;
  margin-left: -2.5vw;
  text-align: left;
  display: flex;
  flex-direction: row;
  padding: 10px;
  z-index: 10;
  


  
  

  
  
}




.dropdown-text-nutrition:hover>.dropdown-nutrition>div>ul{
  list-style: none;
  width: 10vw
  




}

.dropdown-text-nutrition:hover>.dropdown-nutrition>div>ul>li:hover{
  color: yellow;
  text-decoration: underline;
  border-bottom:  0.5px solid black;
  

}

.dropdown-text-nutrition:hover>.dropdown-nutrition>div>ul>li{
  border-bottom:  0.5px solid black;
  padding: 10px;
}


@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.main-page {  
  /* This section calls the slideInFromLeft animation we defined above */
  
  

}


.App-header>img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  /* transition: transform .2s; */
  margin-bottom: -20vh;
  margin-top: 20vh;
  width: 60%;
  height: 130%;
  animation: 1s ease-out 0s 1 slideInFromLeft;

  /* clip-path: polygon(50% 0 round 10px, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%); */
  /* clip-path: polygon(99.98% 51.41%,calc(99.14% - 19.66px) calc(59.21% - 3.69px),97.10% 66.79%,calc(93.89% - 17.56px) calc(73.95% - 9.58px),89.60% 80.52%,calc(84.34% - 13.74px) calc(86.34% - 14.54px),78.23% 91.27%,calc(71.43% - 8.57px) calc(95.17% - 18.07px),64.10% 97.97%,calc(56.42% - 2.57px) calc(99.59% - 19.83px),48.59% 99.98%,calc(40.79% - -3.69px) calc(99.14% - 19.66px),33.21% 97.10%,calc(26.05% - -9.58px) calc(93.89% - 17.56px),19.48% 89.60%,calc(13.66% - -14.54px) calc(84.34% - 13.74px),8.73% 78.23%,calc(4.83% - -18.07px) calc(71.43% - 8.57px),2.03% 64.10%,calc(0.41% - -19.83px) calc(56.42% - 2.57px),0.02% 48.59%,calc(0.86% - -19.66px) calc(40.79% - -3.69px),2.90% 33.21%,calc(6.11% - -17.56px) calc(26.05% - -9.58px),10.40% 19.48%,calc(15.66% - -13.74px) calc(13.66% - -14.54px),21.77% 8.73%,calc(28.57% - -8.57px) calc(4.83% - -18.07px),35.90% 2.03%,calc(43.58% - -2.57px) calc(0.41% - -19.83px),51.41% 0.02%,calc(59.21% - 3.69px) calc(0.86% - -19.66px),66.79% 2.90%,calc(73.95% - 9.58px) calc(6.11% - -17.56px),80.52% 10.40%,calc(86.34% - 14.54px) calc(15.66% - -13.74px),91.27% 21.77%,calc(95.17% - 18.07px) calc(28.57% - -8.57px),97.97% 35.90%,calc(99.59% - 19.83px) calc(43.58% - -2.57px)); */

  

}

.characters-wrapper-div{
  background-image: url(/src/ImagesSRC/backgroundComic.png);
  background-size: 130%;
  background-position-y: 0vh;
  background-position-x: 0 auto;
  background-repeat: no-repeat;
}

.character-images:hover{
  transform: scale(1.2) ;
  
}

.is-active-characeter-desc{
  animation: 1s ease-out 0s 1 slideInFromLeft;

}

.main-page-product{
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.main-page>.main-page-product{
  
  
  background-size: 490vw;
 
}

.App-header{
  height:35vh;
  background-position-y: 10vh;
  background-size: 200vw;
  background-repeat: no-repeat;
}

.header-img{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}



.header-img>div>a{
  font-size: 120%;
  text-decoration: none;
  margin-left: 2vw;
  color: lightgrey;
  margin-top: -2vh;
  
}



.header-img>a:active{
  text-decoration: underline;
  color: grey;
}

/* .App-Header-Wrapper>.header-img{
  background-image: url('/public/wave.svg');
  background-repeat: no-repeat;
  width: 100vw;
  height: 50vh;
} */

.Footer{
  background-color: rgb(31, 29, 29);
  width: 100vw;
  height: 50vh;
}

.Footer>img{
  width: 7%;
  margin-top: 3vh;
  margin-left: 2vw;
}

.Footer>p{
  margin-top: 20vh;
  margin-left: 47vw;

}

.small-footer{
  background-color: rgb(239, 242, 240);
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: center;
}

header{
  padding: 15vh;
  background-color: rgb(31, 29, 29);
  display: flex;
  justify-content: center;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 10;

}

.visible{
  /* display: flex; */
  top: 0;
  transition: top 0.4s ease-out;
}

.hidden{
  /* display: none; */
  top: -35vh;
  transition: top 0.4s ease-out;
}

.App-SmallHeader{
  background-color: #7bc56e;
  width: 100vw;
  /* display: flex;is-acproduct-page-content
  flex-direction: row;
  justify-content: center; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  margin: 0 auto;
}



.product-page-image{
  display: flex;
  flex-direction: row;
  margin-left: 0.7vw;
  
}


.main-page-product>:nth-child(1)>.product-page-image>img{
  /* clip-path: polygon(0 0, 100% 0, 60% 100%, 0% 100%); */
  width: 20vw;
  height: 100%;
  
  
  
 
}


.main-page-product>:nth-child(2)>.product-page-image>img{
  /* clip-path: polygon(40% 0, 100% 0, 50% 100%, 0% 100%); */
  width: 20vw;
  height: 100%;
 
  
  

}

.main-page-product>:nth-child(3)>.product-page-image>img{
  /* clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%); */
  width: 20vw;
  height: 100%;
 
  

  
  
}

.main-page{
  background-color: #e9ecef;
  
}


.product-page-image>img{
  /* width: 0;
  height: 0;
  min-width: calc(100% + var(--s));
  min-height: 100%;
  object-fit: cover;
  cursor: pointer;
  clip-path: polygon(var(--s) 0,100% 0,calc(100% - var(--s)) 100%,0 100%); */
  transition: 0.8s;
  
  
  
  
  
}



.main-page-product>:nth-child(1)>.product-page-image>img:hover{
  
  /* width: 27vw; */
  transform: scale(1.05);
  /* background: linear-gradient(whitesmoke, white); */
 
}

.main-page-product>:nth-child(2)>.product-page-image>img:hover{
  transform: scale(1.05);
 
  /* width: 27vw; */
  
  /* background: linear-gradient(#3e8831, white); */

}

.main-page-product>:nth-child(3)>.product-page-image>img:hover{
  transform: scale(1.05);

   

  /* width: 27vw; */
  /* background: linear-gradient(rgb(149, 40, 149), white)  */
}

.main-page-product>:nth-child(4)>.product-page-image>img:hover{
  
  /* width: 27vw; */
  transform: scale(1.05);
  /* background: linear-gradient(whitesmoke, white); */
 
}

.main-page-product>:nth-child(5)>.product-page-image>img:hover{
  transform: scale(1.05);
 
  /* width: 27vw; */
  
  /* background: linear-gradient(#3e8831, white); */

}

.main-page-product>:nth-child(6)>.product-page-image>img:hover{
  transform: scale(1.05);

   

  /* width: 27vw; */
  /* background: linear-gradient(rgb(149, 40, 149), white)  */
}

.short-product-description{
 
}

.main-page-product>:nth-child(1)>.product-page-image>img:hover .learn-more-product-description {
  
  /* width: 27vw; */
  animation: 1s ease-out 0s 1 slideInFromLeft;
  /* background: linear-gradient(whitesmoke, white); */
 
}

.learn-more-product-description{
  
}

.is-active{
  transform: scale(1.1);
  margin-top: -10px;
 
}

.is-active-product-description{
  transform: scale(1);
  padding: 5px;
  border-radius: 10px;
  font-size: 60%;
  margin: 0 auto;
  display: inline-block;
  margin-bottom: 1vh;
  
  
}


.hide-screen{
  top:0; left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background:grey;
  opacity: 0.5;
  
  display: none;
}

.informatic-page-image>img{
  width: 15vw;
  
}

.informatic-page-image{
  /* margin: 0 auto; */

}

.informatic-product-component{
  width: 100vw;
  display: flex;
  flex-direction: row;
  margin-top: 5vh;
  margin-left: 5vw;
  
  
}

.homepage-link:hover{
  color: black;
}

.homepage-link::after{
  text-decoration: underline;
  color: black;
}

.MuiMasonry-root>.MuiPaper-root:hover{
  color: black;
  transform: scale(1.05);
  transition: 0.5s;
}

.small-footer-button{
  width: 100vw;
  display: flex;
  justify-content: center;

}
.small-footer-button>button{
  background-color: goldenrod;
  width: 10vw;
  padding: 10px;
  margin-bottom: 5vh;
}

.small-footer-button>button:hover{
  background-color: gold;
}

.main-page-header-sale{
  margin: 0 auto;
  margin-top: 40vh;
  display: inline-block;
  background-color: #f8f9fa;
  height: 60vh;
  width: 98%;
  padding: 20px;
  z-index: auto;  

}

.main-page-header-sale>p{
  font-size: 75%;
}



svg {
  width: 50px;
  height: 50px;
}



.recommeded-products-cart-wrapper-div{
  display: flex;
  flex-direction: row;
  margin-left: -1.5vw;
  width: 78vw;
  background-color: #212529;
  height: 22vh;
  padding: 10px;
  justify-content: center;
  margin-bottom: 5vh;
}

.recommeded-products-cart-wrapper-div>.recommended-products-cart{
  width: 100vw;
  margin-left: 0.1vw;
 
}

.recommeded-products-cart-wrapper-div>.recommended-products-cart>.product-page-content>.product-page-image{
  width: 15vw;
  height: 15vh;
  cursor: pointer;


}



/** MEDIA QUERIES **/ 

@media only screen and (min-width: 960px) {
  /* styles for browsers larger than 960px; */

}
@media only screen and (min-width: 1440px) {
  /* styles for browsers larger than 1440px; */
}
@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
}
@media only screen and (max-device-width: 480px) {
 /* styles for mobile browsers smaller than 480px; (iPhone) */

 .App-header>img {
  height: 60%;
 }

 .header-img>div{
    font-size: 50%;
  }
  .header-img>img{
    margin-top: -10vh;
  }
  .App-header>img{
    width: 90vw;
    margin-left: 0vw;
    margin-top: 20vh;

  }

  .App-header{
    width: 100%;


  }
  .main-page>.main-page-product{
    background-size: 300vh;
    background-repeat: no-repeat;
  }

  .product-page-image{
    display: flex;
    flex-direction: row;
    width: 100vw;
    margin-bottom: 5vh;

  }

  .informatic-short-product-description{
    width: 25vw;
    height: 22vh;
  }
  
  .main-page>.main-page-product{
    display: flex;
    flex-direction: column;
    width: 80vw;
    margin-top: 30vh;
  }

  .product-page-image>.short-product-description{
    width: 25vw;
  }





.main-page{
  width: 109vw;
}

.App-Header-Wrapper{
  width: 109vw;
}

.header-stylish-dividers{
  margin: 0 auto;
}

.main-page-header-sale{
  width: 98vw;
  margin-bottom: -10vh;
  height: 100vh;
}

.small-footer{
  width: 109vw;
}

.Footer{
  width: 109vw;
}

.header-img>div>a{
  color: black;
}

}
@media only screen and (device-width: 768px) {
 /* default iPad screens */
}
/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
/* For portrait layouts only */

}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
/* For landscape layouts only */
}